<template>
  <b-row class="invoice-add">
    <b-card class="" style="width:20%">
      <div class="">

        <b-card-title class="mb-2 hover" v-for="item in articleList" @click="changeDetail(item.id)">
          {{ item.title || '' }}
        </b-card-title>

      </div>
    </b-card>
    <b-card class="d-flex align-items-center justify-content-center" style="width:78%;margin-left: 20px">
      <div class="text-center">
        <b-card-title class="mb-2">{{ article.title }}</b-card-title>
        <p class="mb-1">
          <b-badge variant="secondary">{{ article.cate ? article.cate.title : '' }}</b-badge>
        </p>
        <p class="mb-0">{{ article.created_at || '' }}</p>
      </div>
      <!--      <b-img v-if="article.cover" :src="article.cover" fluid alt="封面图"-->
      <!--             class="rounded-0"-->
      <!--      ></b-img>-->
      <b-card-body>
        <b-card-text class="mt-4" v-html="article.content||''">
        </b-card-text>
      </b-card-body>
    </b-card>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BCardBody,
  BBadge,
} from 'bootstrap-vue'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BCardBody,
    BBadge,
    ContentWithSidebar,
  },
  data() {
    return {
      faqSearchQuery: '',
      cate_id: '',
      id: '',
      articleList: [],
      article: {},
    }
  },
  created() {
    if (this.$route.query.cate_id) {
      this.cate_id = this.$route.query.cate_id
    }
    if (this.$route.query.title) {
      this.faqSearchQuery = this.$route.query.title
    }

    this.id = this.$route.query.id
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      axiosIns
          .get(`/article/list?title=${this.faqSearchQuery}&cate_id[0]=1&cate_id[1]=2`)
          .then((res) => {
            this.articleList = res.data.list
            this.articleList.forEach(item => {
              if (item.id == this.id) {
                this.article = item
              }
            })
            this.articleList = this.articleList.slice(0, 10)
          })
    },
    changeDetail(id) {
      this.id = id
      this.fetchDetails()
    }
  },
}
</script>

<style lang="scss">
.hover:hover {
  color: #445f79;
  cursor: pointer;
}
</style>
